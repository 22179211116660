import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";

import "../../styles/Global.css";
import "../../styles/Typography.css";
import styles from "./Checkbox.module.css";

const Checkbox = ({ id, label, checked, onChange, disabled, indeterminate }) => {
    const checkbox = useRef();
    const activeStyles = disabled ? [styles.container, styles.disabled] : [styles.container];

    useEffect(() => {
        checkbox.current.indeterminate = indeterminate;
    }, [indeterminate]);

    return (
        <label className={activeStyles.join(" ")}>
            {label}
            <input id={id} ref={checkbox} type="checkbox" checked={checked} onChange={onChange} disabled={disabled} />
            <span className={styles.indicator}></span>
        </label>
    );
};

export default Checkbox;

Checkbox.propTypes = {
    /** Adds the value as id to the element */
    id: PropTypes.string,
    /** Text to display next to the checkbox  */
    label: PropTypes.string,
    /** Checked status of the checkbox */
    checked: PropTypes.bool,
    /** Disables the checkbox */
    disabled: PropTypes.bool,
    /** Indeterminate status of the checkbox */
    indeterminate: PropTypes.bool,
    /** Gets called when the user clicks on the checkbox */
    onChange: PropTypes.func,
};

Checkbox.defaultProps = {
    checked: false,
    indeterminate: false,
    disabled: false,
};
