import React from "react";
import PropTypes from "prop-types";

import "../../styles/Global.css";
import "../../styles/Typography.css";
import styles from "./InputPassword.module.css";

/**
 * @visibleName Input.Password
 */
const InputPassword = (props) => {
    return (
        <div className={styles.main}>
            <input 
                id={props.id}
                className={styles.input}
                type="password"
                placeholder={props.placeholder} 
                autoComplete="off"
                value={props.value}
                onChange={props.onChange}
            />
            {props.legend !== undefined && props.legend !== "" &&
                <span className={styles.legend}>
                    {props.legend}
                </span>
            }
            {props.required &&
                <span className={styles.required}>Required field</span>
            }
        </div>
    );
};

export default InputPassword;

InputPassword.propTypes = {
    /** Adds the value as id to the element */
    id: PropTypes.string,
    /** Displayed value  */
    value: PropTypes.string,
    /** Gets called when the value is changed */
    onChange: PropTypes.func,
    /** Placeholder text  */
    placeholder: PropTypes.string,
    /** Legend text */
    legend: PropTypes.string,
    /** Is the input required */
    required: PropTypes.bool,
};

InputPassword.defaultProps = {
    required: false,
};
